import { useMemo } from 'react';

import { OfferHeadline } from '../OfferHeadline';
import { OfferConditions } from '../OfferConditions/OfferConditions';
import { OfferDetails } from '../OfferDetails';
import { EditOfferModal } from '../EditOfferModal/EditOfferModal';

// import {
//   useDeleteOffer
//   // DeleteOfferContent
// } from '../DeleteOffersModal/DeleteOffer';

import { Offer } from '@models/campaign';

// import { IconButton, Tooltip } from '@/library/components';
// import { t } from 'i18next';
// import TrashIcon from '@heroicons/react/24/solid/TrashIcon';

// import { ConfirmationModal } from '@/components/ConfirmationModal/ConfirmationModal';
// import { useAuth } from '@/context-providers/auth';

import '../offer.css';

const OfferListRow = ({
  logo,
  merchantName,
  rewardDetails,
  startDate,
  endDate,
  rewardAmount,
  rewardPercent,
  rewardType,
  campaignId,
  adId,
  isMultiRedemption
}: Offer) => {
  // const { isWriteUser } = useAuth();
  const headlineProps = useMemo(
    () => ({
      logo,
      merchantName,
      offerId: adId, // SENDING AdID instead of CampaignID to match what we have at customer level, this should change if we plan to support multi ads in a single campaign
      rewardDetails
    }),
    [logo, merchantName, rewardDetails, adId]
  );

  const conditionsProps = useMemo(
    () => ({
      startDate,
      endDate
    }),
    [startDate, endDate]
  );

  const detailsProps = useMemo(
    () => ({
      rewardAmount,
      rewardPercent,
      rewardType,
      isMultiRedemption
    }),
    [rewardAmount, rewardPercent, rewardType]
  );

  // const handleDeleteOffer = useDeleteOffer(campaignId);

  // const deleteOffer = () => {
  //   handleDeleteOffer();
  // };

  return (
    <div className='my-2 flex flex-row justify-around py-2'>
      <OfferHeadline {...headlineProps} />
      <OfferConditions {...conditionsProps} />
      <OfferDetails {...detailsProps} />

      <div className='ml-12 flex flex-row items-center'>
        <EditOfferModal campaignId={campaignId} />
        {/* {isWriteUser && (
          <div>
            <Tooltip content={t('label.deleteOffer')}>
              <ConfirmationModal
                trigger={
                  <div>
                    <Tooltip content={t('label.deleteOffer')}>
                      <IconButton
                        size='size.md'
                        variant='variant.primary'
                        aria-label={`${t('label.deleteOffer')}`}
                      >
                        <TrashIcon className='remove-stroke text-blue-action' />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                title={t('label.deleteOffer')}
                modalBody={
                  <DeleteOfferContent
                    logo={logo}
                    merchantName={merchantName}
                    campaignId={campaignId}
                  />
                }
                confirmBtnText={t('label.deleteOffer')}
                handleConfirmation={deleteOffer}
              />
            </Tooltip>
          </div>
        )} */}
      </div>
    </div>
  );
};

export { OfferListRow };
