import { getById } from './get-by-id';
import { get } from './get';
import { getEnabled } from './get-enabled';

const institutionAdapter = {
  get,
  getEnabled,
  getById
};

export { institutionAdapter };
