import { datadogRum } from '@datadog/browser-rum';

let objectInitialized = false;

const Datadog = {
  isInitialized() {
    return objectInitialized;
  },

  init() {
    if (Datadog.isInitialized()) {
      return;
    }

    datadogRum.init({
      applicationId: 'f0bcb020-1c2e-4366-b693-bbcce5726336',
      clientToken: 'pub53ccd3350f603b07a59fa315f5a61995',
      site: 'datadoghq.com',
      service: 'self-serve-testing-ui',
      datacenter: 'us',
      env: import.meta.env.MODE,
      version: process.env.PACKAGE_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [/https:\/\/.*\.cardlytics\.com/]
    });

    objectInitialized = true;
  },

  setUser({
    id,
    email,
    firstName,
    lastName
  }: {
    id: string;
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
  }) {
    if (!Datadog.isInitialized()) {
      return;
    }

    datadogRum.setUser({
      id,
      email,
      name: [firstName, lastName].join(' '),
      domain: email?.split('@')[1]
    });
  },

  setInternal(isInternal: boolean) {
    if (!Datadog.isInitialized()) {
      return;
    }

    datadogRum.setUserProperty('internal', isInternal);
  },

  sendEvent(event: string, payload = {}) {
    if (!Datadog.isInitialized()) {
      return;
    }

    datadogRum.addAction(event, payload);
  },

  sendError(error: unknown, context = {}) {
    if (!Datadog.isInitialized()) {
      return;
    }

    datadogRum.addError(error, context);
  },

  getSessionId() {
    if (!Datadog.isInitialized()) {
      return null;
    }

    const context = datadogRum.getInternalContext();
    return context ? context.session_id : null;
  }
};

export { Datadog };
