import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/24/solid';

import { AddOffersModalContent } from './AddOffersModalContent';
import {
  Modal,
  PrimaryButton,
  TablePaginationState
} from '@/library/components';

import { useApiQuery } from '@hooks/use-api-query';

import { Offer } from '@models/campaign';
import { Customer } from '@models/customer';

import { offerService } from '@services/offer';
import { customerService } from '@services/customer';

import { parseCustomerList } from '@utils/customer';
import { useQueryClient } from '@tanstack/react-query';

const AddOffersModal = ({
  isCustomersListEmpty
}: {
  isCustomersListEmpty: boolean;
}) => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();

  const [offerPagination, setOfferPagination] = useState<TablePaginationState>({
    pageIndex: 0,
    pageSize: 5
  });
  const [customerPagination, setCustomerPagination] =
    useState<TablePaginationState>({
      pageIndex: 0,
      pageSize: 5
    });
  const [customerFilterText, setCustomerFilterText] = useState('');

  const [customerFilterRequestText, setCustomerFilterRequestText] =
    useState('');
  const [enableCustomerRequest, setEnableCustomerRequest] =
    useState<boolean>(false);

  const [selectedCampaigns, setSelectedCampaigns] = useState<Offer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);

  const [isOfferTemplatesModalOpen, setIsOfferTemplatesModalOpen] =
    useState(false);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (customerFilterText.length === 0 || customerFilterText.length > 1) {
        setCustomerFilterRequestText(customerFilterText);
        setCustomerPagination({ ...customerPagination, pageIndex: 0 });
        setEnableCustomerRequest(true);
      }
    }, 400);

    return () => clearTimeout(getData);
  }, [customerFilterText]);

  const {
    data: offerResponse,
    isLoading: isLoadingOffers,
    isError: isErrorOffer
  } = useApiQuery(
    offerService.getTemplates({
      params: {
        pagination: offerPagination
      },
      enabled: isOfferTemplatesModalOpen
    })
  );

  const {
    data: customerResponse,
    isError: isErrorCustomer,
    isFetching: isFetchingCustomer
  } = useApiQuery(
    customerService.get({
      params: {
        filter: [
          {
            key: 'sourceCustomerId',
            value: customerFilterRequestText,
            type: 'string'
          }
        ],
        pagination: customerPagination
      },
      enabled: enableCustomerRequest && isOfferTemplatesModalOpen
    })
  );

  const offers = useMemo(() => {
    if (!isLoadingOffers && offerResponse?.offers) {
      return offerResponse.offers.map((offer) => ({
        ...offer,
        rewardPercent: offer.rewardPercent * 100
      }));
    }
    return [] as Offer[];
  }, [offerResponse]);

  const totalOffers = useMemo(
    () =>
      isLoadingOffers || !offerResponse?.meta ? 0 : offerResponse?.meta.total,
    [offerResponse]
  );

  const customers = useMemo(
    () =>
      customerResponse?.customers
        ? parseCustomerList(customerResponse.customers)
        : ([] as Customer[]),
    [customerResponse, isFetchingCustomer]
  );

  const totalCustomers = useMemo(
    () =>
      isFetchingCustomer || !customerResponse?.meta
        ? 0
        : customerResponse?.meta.total,
    [customerResponse]
  );

  const closeAction = async () => {
    setIsOfferTemplatesModalOpen(false);
    setSelectedCampaigns([]);
    setSelectedCustomers([]);
    await queryClient.invalidateQueries({
      queryKey: offerService.invalidationKey
    });
  };

  const handleSelectCampaignChange = ({ ...args }) => {
    const { value, campaign } = args;

    if (value) {
      // add element to the selected list
      setSelectedCampaigns([...selectedCampaigns, campaign]);
    } else {
      // remove element from the selected list
      setSelectedCampaigns(
        selectedCampaigns.filter(
          (offer) => offer.campaignId !== campaign.campaignId
        )
      );
    }
  };

  const handleSelectCustomerChange = ({ ...args }) => {
    const { value, customer } = args;

    if (value) {
      // add element to the selected list
      setSelectedCustomers([...selectedCustomers, customer]);
    } else {
      // remove element from the selected list
      setSelectedCustomers(
        selectedCustomers.filter(
          (customerInstance) =>
            customerInstance.customerId !== customer.customerId
        )
      );
    }
  };

  return (
    <Modal
      isOpen={isOfferTemplatesModalOpen}
      trigger={
        <PrimaryButton
          size='size.md'
          onClick={() => setIsOfferTemplatesModalOpen(true)}
          disabled={isCustomersListEmpty}
        >
          <PlusIcon className='mr-2 h-4 w-4' />
          {t('label.addOffers')}
        </PrimaryButton>
      }
      header={
        <div className='p-4'>
          <h2 className='text-2xl'>{t('label.chooseOffers')}</h2>
          <p className='text-sm font-normal'>{t('label.selectOffersToAdd')}</p>
        </div>
      }
      maxWidth='maxWidth.large'
      onClose={() => closeAction()}
      content={
        isErrorOffer || isErrorCustomer ? (
          <h2 className='w-256 p-8 text-lg font-bold'>
            {t('label.couldNotLoadData')}
          </h2>
        ) : (
          <AddOffersModalContent
            offers={offers}
            customers={customers}
            close={closeAction}
            isLoadingOffers={isLoadingOffers}
            isLoadingCustomers={isFetchingCustomer}
            totalOffers={totalOffers}
            totalCustomers={totalCustomers}
            offerPagination={offerPagination}
            customerPagination={customerPagination}
            selectedCampaigns={selectedCampaigns}
            selectedCustomers={selectedCustomers}
            customerFilterText={customerFilterText}
            onFilterCustomers={setCustomerFilterText}
            onSelectCustomerChange={handleSelectCustomerChange}
            onSelectCampaignChange={handleSelectCampaignChange}
            onOfferPaginationChange={setOfferPagination}
            onCustomerPaginationChange={setCustomerPagination}
          />
        )
      }
    />
  );
};

export { AddOffersModal };
