import { useTranslation } from 'react-i18next';

import {
  ServerSideTable,
  SingleCheckbox,
  tableColumnDefs
} from '@library/components';
import { AddOfferRow } from './AddOfferRow';

import { Offer } from '@models/campaign';

const isCampaignIncluded = (selectedCampaigns, campaign) => {
  if (selectedCampaigns.length === 0) {
    return false;
  }
  return selectedCampaigns.some(
    (selected) => selected.campaignId === campaign.campaignId
  );
};

interface TemplateListProps {
  offers: Offer[];
  isLoading: boolean;
  totalOffers: number;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  isErrorCreation: boolean;
  createOfferResponse: any[];
  selectedCampaigns: Offer[];
  onSelectCampaignChange: (arg) => void;
  onPaginationChange: (arg) => void;
}

const TemplateList = ({
  offers,
  isLoading,
  totalOffers,
  pagination,
  selectedCampaigns,
  createOfferResponse,
  isErrorCreation,
  onPaginationChange,
  onSelectCampaignChange
}: TemplateListProps) => {
  const [t] = useTranslation();

  const columns = [
    tableColumnDefs.custom<Offer>({
      header: '',
      accessorKey: 'campaignId',
      enableSorting: false,
      cell({ row }) {
        return (
          <div className='flex items-center'>
            <div className='mr-2'>
              <SingleCheckbox
                id={`${row.original.campaignId}`}
                disabled={isLoading}
                checked={isCampaignIncluded(selectedCampaigns, row.original)}
                onSelectedItemChange={(value) =>
                  onSelectCampaignChange({
                    value,
                    campaign: row.original
                  })
                }
              />
            </div>
            <AddOfferRow
              {...row.original}
              createOfferResponse={createOfferResponse}
            />
          </div>
        );
      }
    })
  ];
  return (
    <>
      <div className='table-container  h-[30vh]'>
        <ServerSideTable
          id='template-list'
          borderless={false}
          data={offers || []}
          loading={isLoading}
          columns={columns}
          totalRows={totalOffers}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          pageSizeOptions={[5, 10]}
          noDataMessage={`${t('common.noTemplatesForCloning')}`}
        />
      </div>
      <div className='pt-4 text-right'>
        {isErrorCreation && (
          <p className='text-lg font-bold text-red-alerts'>
            {t('common.createOfferError')}
          </p>
        )}
      </div>
    </>
  );
};

export { TemplateList };
