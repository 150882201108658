import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/context-providers/auth';

import { InstitutionGrid } from '@components/Institution/InstitutionGrid/InstitutionGrid';
import { InstitutionGridHeader } from '@components/Institution/InstitutionGrid/InstitutionGridHeader';

import { useApiQuery } from '@hooks/use-api-query';

import type { Institution } from '@models/institution';

import { institutionService } from '@/services/institution';

const InstitutionList = () => {
  const { isCdlxUser, userProfile } = useAuth();
  const [t] = useTranslation();

  const { data, isLoading, isError } = useApiQuery(institutionService.getAll());
  const {
    data: enabledInstitutionsResponse,
    isLoading: isLoadingEnabledInstitutions,
    isError: isErrorEnabledInstitutions
  } = useApiQuery(institutionService.getEnabled());

  const [institutionTextFilter, setInstitutionTextFilter] =
    useState<string>('');
  const [institutionActiveFilter, setInstitutionActiveFilter] =
    useState<boolean>(true);

  const handleFilterTextChange = (filter: string) => {
    setInstitutionTextFilter(filter);
  };

  const handleFilterActiveChange = (filter: boolean) => {
    setInstitutionActiveFilter(filter);
  };

  // const enabledInstitutions = useMemo(() => {
  //   if (!enabledInstitutionsResponse?.institutions) {
  //     return [];
  //   }
  //   return enabledInstitutionsResponse.institutions;
  // }, [enabledInstitutionsResponse]);

  const enabledInstitutions = useMemo(() => {
    if (!enabledInstitutionsResponse?.institutions || !data?.institutions) {
      return [];
    }
    return (
      enabledInstitutionsResponse?.institutions &&
      data.institutions.filter((institution) =>
        enabledInstitutionsResponse?.institutions.some(
          (enabledInstitution) =>
            enabledInstitution.institutionId === `${institution.id}`
        )
      )
    );
  }, [
    enabledInstitutionsResponse,
    data,
    isLoading,
    isLoadingEnabledInstitutions
  ]);

  useEffect(() => {
    if (!isLoading && !isLoadingEnabledInstitutions) {
      console.log('enabledInstitutions', enabledInstitutions);
    }
  }, [enabledInstitutions, isLoading, isLoadingEnabledInstitutions]);

  const filteredInstitutions: Institution[] = useMemo(() => {
    if (
      enabledInstitutions.length === 0 ||
      !Array.isArray(enabledInstitutions)
    ) {
      return [];
    }

    // const filteredEnabledInstitutions =
    //   (enabledInstitutionsResponse?.institutions &&
    //     data.institutions.filter((institution) =>
    //       enabledInstitutionsResponse?.institutions.some(
    //         (enabledInstitution) =>
    //           enabledInstitution.institutionId === `${institution.id}`
    //       )
    //     )) ||
    //   [];
    // console.log(
    //   'filteredEnabledInstitutions IGNORE',
    //   filteredEnabledInstitutions
    // );

    const filteredByText = enabledInstitutions.filter(
      (institution) =>
        institution.name
          .toLowerCase()
          .includes(institutionTextFilter.toLowerCase()) ||
        institution.codeName
          .toLowerCase()
          .includes(institutionTextFilter.toLowerCase()) ||
        institution.id.toString().includes(institutionTextFilter)
    );

    const testInstitutions = filteredByText.filter(
      (institution) => institution.isTest
    );

    const userInstitutions = isCdlxUser
      ? testInstitutions
      : testInstitutions.filter((institution) =>
          userProfile.institutionId.includes(`${institution.id}`)
        );

    const filteredActiveInstitutions = institutionActiveFilter
      ? userInstitutions.filter((institution) => institution.isActive)
      : userInstitutions;

    return filteredActiveInstitutions;
  }, [enabledInstitutions, institutionTextFilter, institutionActiveFilter]);

  return (
    <div>
      <div className='mb-10 border-b-2 border-b-gray-rules py-10'>
        <InstitutionGridHeader
          onFilterText={handleFilterTextChange}
          onFilterActive={handleFilterActiveChange}
          hideInactiveValue={institutionActiveFilter}
        />
      </div>
      {isError || isErrorEnabledInstitutions ? (
        <h2 className='text-xl font-bold'>
          {t('institution.couldNotGetInstitution', { count: 2 })}
        </h2>
      ) : (
        <InstitutionGrid
          institutions={filteredInstitutions}
          isLoading={isLoading || isLoadingEnabledInstitutions}
        />
      )}
    </div>
  );
};

export { InstitutionList };
