import { translate as t } from '@/i18n';

export interface InstitutionLogo {
  fileName: string;
  type: InstitutionLogoType;
}

export type Institution = {
  id: number;
  type: InstitutionType;
  name: string;
  codeName: string;
  description?: string;
  isActive: boolean;
  isTest: boolean;
  country: CountryType;
  operatingCurrencyCode: CurrencyCode;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  logos: InstitutionLogo[];
};

export type EnabledInstitution = {
  institutionId: string;
  country: string;
  useForTemplateCampaigns: boolean;
  useMtls: boolean;
};

export type UpdateInstitution = Omit<
  Institution,
  'logos' | 'createdAt' | 'updatedAt'
> & {
  logoFull?: File | string;
  logoMark?: File | string;
};

export type InstitutionLogoType = 'LogoMark' | 'LogoFull';

export type InstitutionType = 'Cardlytics' | 'Publisher';

// @@@@@@@@@@@@@@@@@@@@@@@@@@
// Add new COUNTRY Info Below
// @@@@@@@@@@@@@@@@@@@@@@@@@@

export const COUNTRIES = {
  US: `${t('country.unitedStates')}`,
  GB: `${t('country.unitedKingdom')}`
};

export type PublisherLocaleCode = 'en-US' | 'en-GB';
export type CurrencyCode = 'USD' | 'GBP';
export type CountryType = 'US' | 'GB';
export type CurrencyMark = '$' | '£';

type CurrencyInfo = {
  code: CurrencyCode;
  mark: CurrencyMark;
  locale: PublisherLocaleCode;
};

type CountryCurrencies = {
  [key in CountryType]: CurrencyInfo;
};

export const COUNTRY_CURRENCIES: CountryCurrencies = {
  US: { code: 'USD', mark: '$', locale: 'en-US' },
  GB: { code: 'GBP', mark: '£', locale: 'en-GB' }
};
