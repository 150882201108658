import axios, { isAxiosError } from 'axios';
import { z } from 'zod';
import { fromZodError } from 'zod-validation-error';
import {
  DeleteOptions,
  GetOptions,
  PatchOptions,
  PostOptions,
  PutOptions,
  TransportOptions,
  TransportResponse
} from './rest-api.types';
import { Auth } from 'aws-amplify';
import { Datadog } from '../datadog';

const instance = axios.create({
  timeout: 0 // no timeout
});

let shouldValidateApiResponseSchema: boolean | undefined;

interface ApiError extends Error {
  response: {
    data: {
      ResponseContent?: string;
      errors?: object;
    };
    status: number;
  };
}

interface ApiErrorResponseContent {
  error?: string;
  errors?: object;
  Errors?: object;
  Detail?: string;
  Message?: string;
}

const instanceOfApiError = (object: Error): object is ApiError =>
  'response' in object;

const extendError = (error: unknown) => {
  let apiError = '';

  if (error instanceof Error && instanceOfApiError(error)) {
    /*
     * ResponseContent, if present, is a stringified JSON object that,
     * once parsed, will contain either a simple error message or an
     * object of errors.
     */
    const responseContent = error.response.data.ResponseContent || '';

    let obj: ApiErrorResponseContent = {};

    try {
      obj = JSON.parse(responseContent) as ApiErrorResponseContent;
    } catch {
      // Do nothing
    }

    /*
     * Due to wonderful inconsistencies in API implementations, if the
     * error is a string, it could still appear in multiple places, so we
     * will have to poke around for it.
     */
    const errorString = obj.error || obj.Detail || obj.Message;

    /*
     * obj.errors/obj.Errors (yay, we get to deal with both) come from the
     * parsed responseContent above. However, it is also possible that the
     * error response already gives us an object of errors that we don't
     * have to parse, hence the second bit. (Yes, this means that the error
     * could be an object OR a string, and that in either case, they can
     * appear in different spots.)
     */
    const errorsObjToStringify =
      obj.errors || obj.Errors || error.response.data.errors;

    /*
     * If we have an object of errors, then for simplicity we're just going
     * to stringify them and show them to the user. Otherwise, if we have a
     * simple string, we'll show that.
     */
    apiError = errorsObjToStringify
      ? JSON.stringify(errorsObjToStringify)
      : errorString || '';

    if (apiError) {
      /*
       * If we found a better error message, we'll move the original to the
       * cause field and replace the message field with the new one.
       * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
       */
      error.cause = error.message;
      error.message = apiError;
    }
  }

  return error;
};

// generic error handler at the transport level
// one "negative" to this handler is that it forces all adapters and services to handle
// TData being a `void` type
const handleError = (error) => {
  error = extendError(error);

  Datadog.sendEvent('ClientErrorResponse', {
    message: error.message,
    status: error.response.status,
    url: error.response.config.url
  });

  Datadog.sendError(error);

  // i cannot find a way to trigger the else handler in tests
  /* istanbul ignore else -- @preserve */
  if (isAxiosError(error)) {
    // TODO: handle 403 for refreshing token in case it applies

    // Can't trigger the else here either (if there is no request nor response)
    /* istanbul ignore else -- @preserve */
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.message);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      if (error.code === 'ERR_CANCELED' && error.config?.url) {
        console.log(`${error.config.url} call canceled`);
      } else {
        console.log(error.toJSON());
      }
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error);
  }

  // must rethrow for react-query
  throw error;
};

instance.interceptors.response.use(
  (response) => response,
  async (error: any) => {
    const config = error.config;

    // If it's a 500 error and it's the first retry, retry the request
    if (error.response && error.response.status === 500 && !config._retry) {
      config._retry = true; // Flag to avoid multiple retries
      try {
        return instance(config); // Retry the request
      } catch {
        // Do nothing
      }
    } else {
      handleError(error); // any other error code nor 2nd 500 fail
    }
  }
);

instance.interceptors.request.use(
  async (config) => {
    // Get the current authenticated user
    const currentUser = await Auth.currentAuthenticatedUser();

    const session = currentUser.signInUserSession;

    if (session.isValid()) {
      const accessToken = session.getIdToken().getJwtToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // If the session is not valid, refresh the tokens
      await Auth.currentSession();
      // Retry the request with the new tokens
      const refreshedUser = await Auth.currentAuthenticatedUser();
      const refreshedAccessToken = refreshedUser.signInUserSession
        .getAccessToken()
        .getJwtToken();
      config.headers.Authorization = `Bearer ${refreshedAccessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

/*
 * Parses the results returned by the API to make sure they match the
 * shape we expect. As we don't want to be overzealous and break our
 * own app if something on the API changes minorly, we'll just log it
 * if parsing fails instead of throwing an error.
 */
const verifyData = <TData>({
  schema,
  data,
  url
}: {
  schema: z.ZodTypeAny | undefined;
  data: TData;
  url: string;
}) => {
  if (!schema) {
    return;
  }

  // TODO: set a launchdarkly flag after that's setup for enabling this fearure using a flag
  if (shouldValidateApiResponseSchema) {
    const schemaValidationResult = schema.safeParse(data);

    if (schemaValidationResult.success) {
      console.info(`Results of ${url} match the given schema`);
    } else {
      console.warn(`Results of ${url} do not match the given schema`);
      console.warn(fromZodError(schemaValidationResult.error));
    }
  }
};

const apiTransport = {
  setOptions({ validateApiResponseSchema }: TransportOptions) {
    shouldValidateApiResponseSchema = validateApiResponseSchema;
  },

  setBaseUrl(url: string) {
    instance.defaults.baseURL = url;
  },

  setHeader({ key, value }: { key: string; value: string | number }) {
    instance.defaults.headers.common[key] = `${value}`;
  },

  setAuthToken(token: string) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },

  async get<TData>({ url, config, schema }: GetOptions<TData>) {
    const { data, headers } = await instance.get<TData>(url, config);
    if (schema) {
      verifyData({ schema, data, url });
    }

    const res: TransportResponse<TData> = {
      data
    };

    // If pagination headers exist, add them to the constructed response
    const total = headers['pagination-totalcount'];
    if (total) {
      res.meta = { total: Number.parseInt(total, 10) };
    }

    return res;
  },

  async put<TData>({ url, body, config, schema }: PutOptions<TData>) {
    const { data } = await instance.put<TData>(url, body, config);

    if (schema) {
      verifyData({ schema, data, url });
    }

    const res: TransportResponse<TData> = {
      data
    };

    return res;
  },

  async putForm<TData>({ url, body, config, schema }: PostOptions<TData>) {
    const { data } = await instance.putForm<TData>(url, body, config);

    if (schema) {
      verifyData({ schema, data, url });
    }

    const res: TransportResponse<TData> = {
      data
    };

    return res;
  },

  async post<TData>({ url, body, config, schema }: PostOptions<TData>) {
    const { data } = await instance.post<TData>(url, body, config);

    if (schema) {
      verifyData({ schema, data, url });
    }

    const res: TransportResponse<TData> = {
      data
    };

    return res;
  },

  async postForm<TData>({ url, body, config, schema }: PostOptions<TData>) {
    const { data } = await instance.postForm<TData>(url, body, config);

    if (schema) {
      verifyData({ schema, data, url });
    }

    const res: TransportResponse<TData> = {
      data
    };

    return res;
  },

  async patch<TData>({ url, body, config, schema }: PatchOptions<TData>) {
    // Do not make a call if nothing is actually getting patched
    if (body.length === 0) {
      return;
    }

    const { data } = await instance.patch<TData>(url, body, config);

    if (schema) {
      verifyData({ schema, data, url });
    }

    const res: TransportResponse<TData> = {
      data
    };
    return res;
  },

  async delete({ url }: DeleteOptions) {
    await instance.delete<void>(url);
  }
};

export type {
  GetOptions,
  PatchOptions,
  PostOptions,
  TransportError,
  TransportOptions,
  TransportResponse,
  TransportResponseMeta
} from './rest-api.types';
export { apiTransport, instanceOfApiError };
