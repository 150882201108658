import React from 'react';
import ReactDOM from 'react-dom/client';

// import { sentry } from '@/config';

import { ContextProviders } from '@/context-providers';

import { apiTransport } from '@services/rest-api';

import { Router } from './router';

import '@assets/stylesheet/index.css';
import { Datadog } from './datadog';

// top-level async requires es2022 target
// https://caniuse.com/?search=top-level%20await
// eslint-disable-next-line @typescript-eslint/require-await
const prepare = async () => {
  apiTransport.setBaseUrl(process.env.API_URL);
};

Datadog.init();

await prepare();
// sentry.init();

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ContextProviders>
      <Router />
    </ContextProviders>
  </React.StrictMode>
);
