import { ServiceOptions, wrapQueryFunction } from '../service';

import { getById } from './get-by-id';
import { getAll } from './get';
import { getEnabled } from './get-enabled';

const options: ServiceOptions = {
  name: 'INSTITUTION',
  cacheTime: 'cache.none'
};

const institutionService = {
  invalidationKey: [{ name: options.name }],
  getById: wrapQueryFunction(options, getById),
  getAll: wrapQueryFunction(options, getAll),
  getEnabled: wrapQueryFunction(options, getEnabled)
};

export { institutionService };
