import { useMemo } from 'react';

import { OfferHeadline } from '../OfferHeadline';
import { OfferConditions } from '../OfferConditions/OfferConditions';
import { OfferDetails } from '../OfferDetails';

import { Offer } from '@models/campaign';

import '../offer.css';

export interface AddOfferComponentProps extends Offer {
  createOfferResponse?: any[];
}

const AddOfferRow = ({
  adId,
  logo,
  merchantName,
  rewardDetails,
  startDate,
  endDate,
  rewardAmount,
  rewardPercent,
  rewardType,
  createOfferResponse,
  isMultiRedemption
}: AddOfferComponentProps) => {
  const headlineProps = useMemo(
    () => ({
      logo,
      merchantName,
      rewardDetails,
      offerId: adId,
      createOfferResponse
    }),
    [logo, merchantName, rewardDetails, adId, createOfferResponse]
  );

  const conditionsProps = useMemo(() => {
    const today = new Date();
    const campaignEndDate = new Date();
    campaignEndDate.setDate(today.getDate() + 180);

    return {
      startDate: today.toISOString(),
      endDate: campaignEndDate.toISOString()
    };
  }, [startDate, endDate]);

  const detailsProps = useMemo(
    () => ({
      rewardAmount,
      rewardPercent,
      rewardType,
      isMultiRedemption
    }),
    [rewardAmount, rewardPercent, rewardType, isMultiRedemption]
  );

  return (
    <div className='my-2 flex flex-row justify-around py-2'>
      <OfferHeadline {...headlineProps} />
      <OfferConditions {...conditionsProps} />
      <OfferDetails {...detailsProps} />
    </div>
  );
};

export { AddOfferRow };
