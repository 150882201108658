import { Dispatch, SetStateAction, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import {
  PrimaryButton,
  SecondaryButton,
  TextInput,
  useToasts
} from '@library/components';

interface ForgotPasswordFlowProps {
  setLoginFlow: Dispatch<
    SetStateAction<'MFA' | 'NEW_PASSWORD' | 'MAIN' | 'FORGOT_PASSWORD'>
  >;
  credentials: { email: string; password: string };
}

const ForgotPasswordFlow = ({
  setLoginFlow,
  credentials
}: ForgotPasswordFlowProps) => {
  const [t] = useTranslation();
  const { sendToast } = useToasts();

  const [email, setEmail] = useState<string>(credentials.email || '');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [receivedCode, setReceivedCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const onCancelClick = () => {
    setLoginFlow('MAIN');
  };

  const onRequestCodeClick = async () => {
    setIsChangingPassword(true);
    if (email.length === 0) {
      setErrorMessage(`Email should not be empty`);
    } else {
      setErrorMessage('');

      try {
        await Auth.forgotPassword(email);
        sendToast({
          variant: 'variant.success',
          content: `Code sent, please check your email inbox`
        });
        setIsCodeSent(true);
      } catch (error) {
        sendToast({
          variant: 'variant.error',
          content: `${error}`
        });
      }
    }
    setIsChangingPassword(false);
  };

  const onChangePasswordClick = async () => {
    setIsChangingPassword(true);
    try {
      await Auth.forgotPasswordSubmit(email, receivedCode, newPassword);
      sendToast({
        variant: 'variant.success',
        content: `Password Updated!`
      });
      setLoginFlow('MAIN');
    } catch (error) {
      sendToast({
        variant: 'variant.error',
        content: `${error}`
      });
    }
    setIsChangingPassword(false);
  };

  return (
    <div>
      <p className='mb-2'>{t('label.forgotPassword')}?</p>
      <div className='mb-2'>
        <TextInput
          className='flex-1'
          value={email}
          onChange={setEmail}
          size='size.md'
          placeholder='Email'
          type='text'
          clearable={false}
          disabled={isCodeSent}
        />
      </div>
      {isCodeSent && (
        <div>
          <p className='mb-2 mt-4 text-sm'>
            Please input the received code and the new password
          </p>
          <div className='mb-2'>
            <TextInput
              className='flex-1'
              value={receivedCode}
              onChange={setReceivedCode}
              size='size.md'
              placeholder='Code'
              type='text'
              clearable={false}
            />
          </div>
          <TextInput
            className='flex-1'
            value={newPassword}
            onChange={setNewPassword}
            size='size.md'
            placeholder='New password'
            type='password'
            clearable={false}
          />
        </div>
      )}
      {errorMessage ? (
        <p className='m-0 mt-1 text-sm text-error'>{errorMessage}</p>
      ) : null}
      <div className=' mt-4 flex items-center justify-between'>
        <SecondaryButton onClick={onCancelClick} disabled={isChangingPassword}>
          {t('library.cancel')}
        </SecondaryButton>
        {isCodeSent ? (
          <PrimaryButton
            onClick={onChangePasswordClick}
            disabled={isChangingPassword}
          >
            {isChangingPassword
              ? t('label.updatingEllipsis')
              : t('label.updatePassword')}
          </PrimaryButton>
        ) : (
          <PrimaryButton
            onClick={onRequestCodeClick}
            disabled={isChangingPassword}
          >
            {t('label.continue')}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export { ForgotPasswordFlow };
