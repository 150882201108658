import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderMenu } from '@components/HeaderMenu/HeaderMenu';
import {
  AppHeader as LibraryHeader,
  PrimaryButton,
  PrimaryLink
} from '@library/components';
import type { NavItem, NavItemParent } from '@library/components';

import { useInstitution } from '@/context-providers/institution';

import { getLogoUrl } from '@utils/institution';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const AppHeader = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { institutionInfo } = useInstitution();
  const navItems: (NavItem | NavItemParent)[] = [
    {
      text: 'Self-Serve Testing',
      url: `/`
    }
  ];

  const institutionLogoUrl = useMemo(
    () =>
      getLogoUrl({
        logos: institutionInfo.logos ?? [],
        type: 'LogoFull',
        institutionId: institutionInfo.id
      }),
    [institutionInfo]
  );

  const institutionName = useMemo(
    () => (institutionInfo && institutionInfo.name) || '',
    [institutionInfo]
  );

  const handleSwitchOPublisher = async () => {
    await queryClient.invalidateQueries();
    navigate(`/publishers`);
  };

  return (
    <LibraryHeader
      navItems={navItems}
      logoUrl={institutionLogoUrl}
      logoText={institutionName}
      rightSlot={
        <div className='mr-2'>
          <PrimaryLink to='/publishers' size='size.sm'>
            {t('institution.switchPublisher')}
          </PrimaryLink>
          <div className='hidden'>
            <PrimaryButton size='size.sm' onClick={handleSwitchOPublisher}>
              {t('institution.switchPublisher')}
            </PrimaryButton>
          </div>
        </div>
      }
    >
      <div className='h-full items-center gap-4'>
        <HeaderMenu />
      </div>
    </LibraryHeader>
  );
};

export { AppHeader };
