import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { EnabledInstitution } from '@models/institution';

const getEnabled: AdapterFunction<EnabledInstitution[]> = ({ signal }) => {
  const url = '/v1/institutions';

  // apiTransport.setBaseUrl(process.env.PM_API_URL);

  const response = apiTransport.get<EnabledInstitution[]>({
    url,
    config: {
      signal
    }
  });

  // apiTransport.setBaseUrl(process.env.API_URL);

  return response;
};

export { getEnabled };
